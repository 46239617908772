import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { Section, Button, Title, Text, Box } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import CTA from "../sections/common/CTA";
import { device } from "../utils";

const WorkSingle = () => {
  return (
    <>
      <PageWrapper darkTheme>
        <Section hero>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <Box>
                  <Text variant="tag">Illustration Projects</Text>
                  <Title className="my-4">
                    Illustrated Short Story <br /> Revelations
                  </Title>
                </Box>
              </Col>
            </Row>

            <Box pt={["4rem", null, null, "6.25rem"]}>
              <img
                src="/projects/revelations/book.jpg?nf_resize=fit&w=1140"
                alt=""
                className="img-fluid w-100"
              />
            </Box>
          </Container>
        </Section>

        <div className="mt-lg-3">
          <Container>
            <Row className="justify-content-center">
              <Col lg="8">
                <Box>
                  <Text
                    variant="p"
                    css={`
                      max-width: 750px;
                    `}
                  >
                    A cyberpunk aesthetic with a self-hating catholic
                    anti-heroine seeks revenge on those who wronged her and her
                    community.
                  </Text>

                  <Title variant="cardBig" className="mt-3 pb-3">
                    Narrative Concept
                  </Title>
                  <Text
                    variant="p"
                    css={`
                      max-width: 750px;
                    `}
                  >
                    Andrea sought protection at a catholic church after the
                    riots of 2066 in the south side of Chicago. It was a
                    peaceful protest turned riot when an unknown official gave
                    the command to use any forces necessary to remove protesters
                    from the streets. She was severely hurt and after watching
                    her loved ones die in-front of her she had no where else to
                    turn to for sanctuary. Luckily for Andrea the person who
                    found her bloody and barely alive in a confessional was an
                    ex-military cybernetic surgeon who fled and sought
                    protection from the catholic church. This could be his
                    chance of redemption and salvation from his dark past. He
                    rescued Andrea and helped her back on her feet. Now almost
                    ten years later Andrea is determined to find the person who
                    took the lives of all the people she cared about, and if she
                    saves a few lives on the way, well, that will just be the
                    cherry on top.
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>
          <div className="mt-lg-3 pt-5">
            <Container>
              <Row className="justify-content-center ">
                <Col lg="8" className="mb-4 mb-lg-0">
                  <Text variant="tag">Tools Used</Text>
                  <Title variant="cardBig" className="mt-3">
                    Procreate, Photoshop, InDesign
                  </Title>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Section className="pb-0">
          <Section>
            <Container>
              <Row>
                <Col lg="6" className="mb-5 mb-lg-0 pr-lg-4">
                  <img
                    src="/projects/revelations/02.jpg?nf_resize=fit&w=531"
                    alt=""
                    className="img-fluid w-100"
                  />
                </Col>
                <Col lg="6" className="mb-5 pl-lg-4">
                  <img
                    src="/projects/revelations/05.jpg?nf_resize=fit&w=531"
                    alt=""
                    className="img-fluid w-100"
                  />
                </Col>
                <Col lg="6" className="mb-5 mb-lg-0 pr-lg-4">
                  <img
                    src="/projects/revelations/09.jpg?nf_resize=fit&w=531"
                    alt=""
                    className="img-fluid w-100"
                  />
                </Col>
                <Col lg="6" className="mb-5 pl-lg-4">
                  <img
                    src="/projects/revelations/12.jpg?nf_resize=fit&w=531"
                    alt=""
                    className="img-fluid w-100"
                  />
                </Col>
                <Col xs="12" className="mb-5">
                  <img
                    src="/projects/revelations/book2.jpg?nf_resize=fit&w=1140"
                    alt=""
                    className="img-fluid w-100"
                  />
                </Col>
              </Row>
            </Container>
          </Section>
        </Section>

        <Section bg="dark" className="pt-0">
          <Container>
            <div className="text-center mt-lg-5">
              <Text variant="tag" className="mb-1" color="lightShade">
                Next Project
              </Text>
              <Link to="/skin-script">
                <Button
                  arrowRight
                  className="border-0 bg-transparent shadow-none text-capitalize py-3"
                  css={`
                    font-weight: 700
                    font-size: 1.5rem;
                    letter-spacing: -1.2px;
                    line-height: 1.375;
                    @media ${device.md} {
                      font-size: 2rem;
                    }
                `}
                >
                  Brand Identity Refresh - Skin Script
                </Button>
              </Link>
            </div>
          </Container>
        </Section>
        <Box py={4}>
          <CTA />
        </Box>
      </PageWrapper>
    </>
  );
};
export default WorkSingle;
